//
//
// https://tieto.nurminen.dev sivuston lähdekoodi
//
// Nurminen Development Oy Ltd - https://nurminen.dev
//
// ALL RIGHTS RESERVED
//
//

//
// File author(s):
//   - Riku Nurminen <riku@nurminen.dev>
//


//
// webpack entry file
//

import '../css/styles.scss'

import smoothscroll                 from 'smoothscroll-polyfill'
import * as zenscroll               from 'zenscroll'
import MicroModal                   from 'micromodal'
import { Notyf }                    from 'notyf'

import 'notyf/notyf.min.css';

import 'FRONTEND/images/favicon.ico'

export const notyf = new Notyf({
    duration: 5000,
    dismissible: true
})

smoothscroll.polyfill()

const zenscrollDefaultDuration = 300 // ms
const zenscrollEdgeOffset = 25 // px
zenscroll.setup(zenscrollDefaultDuration, zenscrollEdgeOffset)



// 
// 
// ██╗      ██████╗  ██████╗ ██╗███╗   ██╗
// ██║     ██╔═══██╗██╔════╝ ██║████╗  ██║
// ██║     ██║   ██║██║  ███╗██║██╔██╗ ██║
// ██║     ██║   ██║██║   ██║██║██║╚██╗██║
// ███████╗╚██████╔╝╚██████╔╝██║██║ ╚████║
// ╚══════╝ ╚═════╝  ╚═════╝ ╚═╝╚═╝  ╚═══╝
//
//
async function register(e) {
    e.preventDefault()

    try {
        const emailInputEl = document.getElementById('kurssi-rekisteroidy-email')
        const email = emailInputEl?.value

        if(!email) {
            return notyf.error('Syötä sähköposti rekisteröityäksesi')
        }

        const payload = { email }

        loginLoading()
        showLoadingIndicator()

        await backendApiKutsu('POST', '/api/user/register', payload)

        const registerCourseEl = document.getElementById('maksuton-kurssi-rekisteroidy')

        registerCourseEl.style.display = 'none'

        const registerSuccessEl = document.getElementById('kiitos-rekisteroitymisesta')

        registerSuccessEl.style.display = 'block'

    } catch(error) {
        console.error(error)
        notyf.error('Palvelinvirhe. Jos ongelma jatkuu, ota yhteys kurssit@nurminen.dev')
    } finally {
        hideLoadingIndicator()
        loginLoading(false)
    }
}



// 
// 
// ██╗      ██████╗  ██████╗ ██╗███╗   ██╗
// ██║     ██╔═══██╗██╔════╝ ██║████╗  ██║
// ██║     ██║   ██║██║  ███╗██║██╔██╗ ██║
// ██║     ██║   ██║██║   ██║██║██║╚██╗██║
// ███████╗╚██████╔╝╚██████╔╝██║██║ ╚████║
// ╚══════╝ ╚═════╝  ╚═════╝ ╚═╝╚═╝  ╚═══╝
//
//
async function login(from, e) {
    e.preventDefault()

    try {
        const emailElId = from === 'top-login' ? 'tieto-kirjaudu-email' : 'kurssi-kirjaudu-email'
        const pwdElId = from === 'top-login' ? 'tieto-kirjaudu-salasana' : 'kurssi-kirjaudu-salasana'
        const staySigneElId = from === 'top-login' ? 'tieto-pysy-kirjautuneena' : 'kurssi-pysy-kirjautuneena'

        const emailInputEl = document.getElementById(emailElId)
        const pwdInputEl = document.getElementById(pwdElId)

        const email = emailInputEl?.value
        const password = pwdInputEl?.value

        if(!email || !password) {
            return notyf.error('Syötä sähköposti ja salasana')
        }

        const staySignedInCheckboxEl = document.getElementById(staySigneElId)
        const staySignedIn = staySignedInCheckboxEl?.checked || false

        const payload = { email, password, staySignedIn }

        loginLoading()
        showLoadingIndicator()

        const userData = await backendApiKutsu('POST', '/api/user/login', payload)

        await setLoggedIn(userData)

    } catch(error) {
        if(error?.status === 401) {
            notyf.error('Väärä sähköpostiosoite tai salasana')
        } else {
            console.error(error)
            notyf.error('Palvelinvirhe. Jos ongelma jatkuu, ota yhteys kurssit@nurminen.dev')
        }
    } finally {
        hideLoadingIndicator()
        loginLoading(false)
    }

}


async function logout() {
    try {
        showLoadingIndicator('Kirjaudutaan ulos...')

        await backendApiKutsu('POST', '/api/user/logout')

        window.location.reload()

    } catch(error) {
        console.error(error)

        notyf.error('Uloskirjautuminen ei onnistu, jos ongelma jatkuu, ota yhteys kurssit@nurminen.dev')
    } finally {
        hideLoadingIndicator()
    }

}



// 
// 
//  █████╗ ██╗   ██╗████████╗██╗  ██╗
// ██╔══██╗██║   ██║╚══██╔══╝██║  ██║
// ███████║██║   ██║   ██║   ███████║
// ██╔══██║██║   ██║   ██║   ██╔══██║
// ██║  ██║╚██████╔╝   ██║   ██║  ██║
// ╚═╝  ╚═╝ ╚═════╝    ╚═╝   ╚═╝  ╚═╝
//
//
async function authenticate() {
    try {
        loginLoading()
        showLoadingIndicator()

        const userData = await backendApiKutsu('POST', '/api/user/authenticate')

        await setLoggedIn(userData)

    } catch(error) {
        // No active login session / cookie
    } finally {
        hideLoadingIndicator()
        loginLoading(false)
    }

}



//
//
// ███████╗███████╗████████╗██╗      ██████╗  ██████╗ ██╗███╗   ██╗
// ██╔════╝██╔════╝╚══██╔══╝██║     ██╔═══██╗██╔════╝ ██║████╗  ██║
// ███████╗█████╗     ██║   ██║     ██║   ██║██║  ███╗██║██╔██╗ ██║
// ╚════██║██╔══╝     ██║   ██║     ██║   ██║██║   ██║██║██║╚██╗██║
// ███████║███████╗   ██║   ███████╗╚██████╔╝╚██████╔╝██║██║ ╚████║
// ╚══════╝╚══════╝   ╚═╝   ╚══════╝ ╚═════╝  ╚═════╝ ╚═╝╚═╝  ╚═══╝
//
//
async function setLoggedIn(userData) {
    // Close and hide login menu
    const loginMenuEl = document.getElementById('login-menu')
    loginMenuEl.classList.remove('is-active')
    loginMenuEl.style.display = 'none'

    // Show and setup user menu
    const userMenuEl = document.getElementById('user-menu')
    
    if(!userMenuEl) return

    userMenuEl.style.display = 'inline-flex'

    const accountEmailEl = document.getElementById('user-menu-account-email')
    if(accountEmailEl) {
        accountEmailEl.innerHTML = userData.email
    }

    await setFrontPage(userData)
    await setThankYouPage(userData)
    await setCoursePage(userData)
    await setAccountPage(userData)
}



//
//
// ███████╗██████╗  ██████╗ ███╗   ██╗████████╗██████╗  █████╗  ██████╗ ███████╗
// ██╔════╝██╔══██╗██╔═══██╗████╗  ██║╚══██╔══╝██╔══██╗██╔══██╗██╔════╝ ██╔════╝
// █████╗  ██████╔╝██║   ██║██╔██╗ ██║   ██║   ██████╔╝███████║██║  ███╗█████╗
// ██╔══╝  ██╔══██╗██║   ██║██║╚██╗██║   ██║   ██╔═══╝ ██╔══██║██║   ██║██╔══╝
// ██║     ██║  ██║╚██████╔╝██║ ╚████║   ██║   ██║     ██║  ██║╚██████╔╝███████╗
// ╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═══╝   ╚═╝   ╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚══════╝
//
//
async function setFrontPage(userData) {
    const frontPageEl = document.getElementById('tieto-etusivu')

    if(!frontPageEl) return

    // If logged in; remove price from courses that the user owns
    const userProducts = Array.isArray(userData?.products) ? userData.products : []

    const courseEntries = document.getElementsByClassName('kurssi-entry')

    for(let i = 0; i < courseEntries.length; i++) {
        const courseEntry = courseEntries[i]

        const freeCourseEls = courseEntry.getElementsByClassName('kurssi-maksuton')

        if(freeCourseEls.length === 1) {
            // This is a free course, hide "free" tag and go to next course
            freeCourseEls[0].style.display = 'none'
            continue
        }

        const coursePriceEls = courseEntry.getElementsByClassName('kurssi-hinta-tutustu')

        if(coursePriceEls.length !== 2) continue

        // Non-free course, hide if owned
        const courseCode = courseEntry?.dataset?.kurssi || ''

        const courseOwned = userProducts.find(p => p.sku === courseCode)

        const coursePriceEl = coursePriceEls[0]
        const coursePreviewEl = coursePriceEls[1]

        if(courseOwned) {
            coursePriceEl.style.display = 'none'
            coursePreviewEl.style.display = 'none'
        }

    }
}



function mailingListHandler(source) {
    const contentEl = document.getElementById(`${source}-postituslista`)
    const formEl = document.getElementById(`${source}-postituslista-lomake`)
    const emailEl = document.getElementById(`${source}-postituslista-email`)

    if(contentEl && formEl && emailEl) {
        formEl.addEventListener('submit', async (e) => {
            e.preventDefault()

            try {
                showLoadingIndicator()

                const payload = {
                    email: emailEl?.value,
                    source
                }
        
                await backendApiKutsu('POST', '/api/kurssi/mailing-list', payload)

            } catch(error) {
                // Ignore errors here
            } finally {
                hideLoadingIndicator()

                contentEl.innerHTML = `<p>Liityit postituslistalle.</p>`
            }
        })
    }
}



//
//
// ████████╗██╗  ██╗ █████╗ ███╗   ██╗██╗  ██╗██╗   ██╗ ██████╗ ██╗   ██╗
// ╚══██╔══╝██║  ██║██╔══██╗████╗  ██║██║ ██╔╝╚██╗ ██╔╝██╔═══██╗██║   ██║
//    ██║   ███████║███████║██╔██╗ ██║█████╔╝  ╚████╔╝ ██║   ██║██║   ██║
//    ██║   ██╔══██║██╔══██║██║╚██╗██║██╔═██╗   ╚██╔╝  ██║   ██║██║   ██║
//    ██║   ██║  ██║██║  ██║██║ ╚████║██║  ██╗   ██║   ╚██████╔╝╚██████╔╝
//    ╚═╝   ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═══╝╚═╝  ╚═╝   ╚═╝    ╚═════╝  ╚═════╝
//
//

async function setThankYouPage(userData) {
    const thankYouPageEl = document.getElementById('kiitos-ostoksestasi-sivu')

    if(!thankYouPageEl) return

    const params = new URLSearchParams(window.location.search)

    let course = ''

    if(params.has('kurssi')) {
        course = params.get('kurssi')
    }

    const courseName = course ? `<strong>${course}</strong>` : ''

    let html

    html = `
        <h2>Kiitos ostoksestasi!</h2>
        <p>
          Kurssi ${courseName} on lisätty tiliisi <strong>${userData.email}</strong>
        </p>
    `

    if(course) {
        html += `
            <div>
                <a class="button is-primary" href="/${course}" title="Siirry kurssiin">Siirry kurssiin</a>
            </div>
        `
    }

    thankYouPageEl.innerHTML = html
}



//
//
//  █████╗  ██████╗ ██████╗ ██████╗ ██╗   ██╗███╗   ██╗████████╗
// ██╔══██╗██╔════╝██╔════╝██╔═══██╗██║   ██║████╗  ██║╚══██╔══╝
// ███████║██║     ██║     ██║   ██║██║   ██║██╔██╗ ██║   ██║
// ██╔══██║██║     ██║     ██║   ██║██║   ██║██║╚██╗██║   ██║
// ██║  ██║╚██████╗╚██████╗╚██████╔╝╚██████╔╝██║ ╚████║   ██║
// ╚═╝  ╚═╝ ╚═════╝ ╚═════╝ ╚═════╝  ╚═════╝ ╚═╝  ╚═══╝   ╚═╝
//
//
async function setAccountPage(userData) {
    const accountEl = document.getElementById('tili-kayttajatiedot')
    const mailingListCheckboxEl = document.getElementById('tili-sivu-postituslista-jasenyys')
    const productsEl = document.getElementById('tili-sivu-ostetut-tuotteet')

    if(!accountEl || !mailingListCheckboxEl || !productsEl) return

    if(typeof userData?.mailing_list === 'boolean') {
        mailingListCheckboxEl.checked = userData.mailing_list
    } else {
        mailingListCheckboxEl.checked = true
    }

    mailingListCheckboxEl.addEventListener('change', async (e) => {
        try {
            const url = `/api/user`

            const mailing_list = e.currentTarget.checked ? true : false

            const payload = { mailing_list }

            showLoadingIndicator()
    
            await backendApiKutsu('PATCH', url, payload)
    
            if(mailing_list) {
                notyf.success('Liityit postituslistalle')
            } else {
                notyf.success('Poistuit postituslistalta')
            }
        } catch(error) {
            notyf.error('Palvelinvirhe, yritä uudelleen hetken kuluttua')
        } finally {
            hideLoadingIndicator()
        }
    })

    let fullName = ''

    if(userData.firstname || userData.lastname) {
        fullName = `<strong>${userData.firstname || ''} ${userData.lastname || ''}</strong><br>`
    }

    accountEl.innerHTML = `
        ${fullName}
        ${userData.email}
    `

    if(!Array.isArray(userData?.products) || userData.products.length === 0) {
        const freeAccountEl = document.getElementById('tili-ilmaistili')

        freeAccountEl.style.display = 'block'

        const mailingListEl = document.getElementById('postituslista')

        mailingListEl.style.display = 'none'

        const deleteAccountEl = document.getElementById('ilmaistili-poista')

        deleteAccountEl.addEventListener('click', async () => {
            if(window.confirm('Haluatko varmasti poistaa tilisi?')) {
                deleteMe()
            }
        })
        return
    }

    const productDescriptions = {
        'devops-perusteet-2': 'DevOps perusteet 2/2: Websovellus Linux-palvelimelle'
    }

    const { default: dayjs } = await import('dayjs')

    let html = ''

    for(let product of userData.products) {
        const productDesc = productDescriptions[product.sku] || product.sku

        let purchasedAtHtml = ''

        const purchasedAt = dayjs(product.purchased_at)

        if(purchasedAt.isValid()) {
            purchasedAtHtml = `
            <div class="oikea-alateksti tili">${purchasedAt.format("D.M.YYYY")}</div>
            `
        }

        html += `
        <section class="kurssi-entry">
          <a class="tili" href="/${product.sku}" title="${productDesc}">
            <div class="vasen-content">
              <h2 class="tili">${productDesc}</h2>
            </div>
            <div class="oikea-boksi tili">
              <div class="oikea-teksti tili">Ostettu</div>
              ${purchasedAtHtml}
            </div>
          </a>
        </section>
        `
    }

    productsEl.innerHTML = html
}


async function deleteMe() {
    try {
        showLoadingIndicator()

        await backendApiKutsu('POST', '/api/user/deleteme', { confirm: 'yes' })

        window.location.replace('/')

    } catch(error) {
        console.error(error)
        notyf.error('Jotain meni pieleen, yritä uudelleen tai ota yhteys kurssit@nurminen.dev')
    } finally {
        hideLoadingIndicator()
    }

}


//
//
// ██████╗  █████╗ ███████╗███████╗██╗    ██╗ ██████╗ ██████╗ ██████╗
// ██╔══██╗██╔══██╗██╔════╝██╔════╝██║    ██║██╔═══██╗██╔══██╗██╔══██╗
// ██████╔╝███████║███████╗███████╗██║ █╗ ██║██║   ██║██████╔╝██║  ██║
// ██╔═══╝ ██╔══██║╚════██║╚════██║██║███╗██║██║   ██║██╔══██╗██║  ██║
// ██║     ██║  ██║███████║███████║╚███╔███╔╝╚██████╔╝██║  ██║██████╔╝
// ╚═╝     ╚═╝  ╚═╝╚══════╝╚══════╝ ╚══╝╚══╝  ╚═════╝ ╚═╝  ╚═╝╚═════╝
//
//

let passwordResetEmail, passwordResetCode

async function setPasswordPage() {
    const passwordPageEl = document.getElementById('salasana-sivu')

    if(!passwordPageEl) return

    setupPasswordResetCodeInputs()

    const step1form = document.getElementById('uusi-salasana-step1-form')
    step1form.addEventListener('submit', step1_sendVerificationCode)

    const step2submit = document.getElementById('laheta-vahvistuskoodi')
    step2submit.addEventListener('pointerup', step2_checkVerificationCode.bind(null, null))

    const step2resendCode = document.getElementById('laheta-uusi-koodi')
    step2resendCode.addEventListener('pointerup', step1_sendVerificationCode.bind(null, null, true))

    const step3form = document.getElementById('uusi-salasana-step3-form')
    step3form.addEventListener('submit', step3_setNewPassword)

    const params = new URLSearchParams(window.location.search)

    if(params.has('email') && params.has('verification_code')) {
        passwordResetEmail = params.get('email')
        passwordResetCode = params.get('verification_code')

        setStep(3)

        await step2_checkVerificationCode(passwordResetCode)
    }
}


async function step1_sendVerificationCode(e, resend = false) {
    if(!resend) e.preventDefault()

    if(resend && resendDisabled) return

    const errorEl = document.getElementById('step1-virhe')

    errorEl.innerHTML = ''

    try {
        if(!resend) {
            const emailInputEl = document.getElementById('uusi-salasana-step1-email')
            passwordResetEmail = emailInputEl?.value
        }

        if(!passwordResetEmail) return

        showLoadingIndicator()

        const url = `/api/user/verification-mail`

        const payload = { email: passwordResetEmail }

        await backendApiKutsu('POST', url, payload)

        notyf.success(`Vahvistuskoodi lähetetty (${passwordResetEmail})`)

        if(!resend) {
            setStep(2)
        } else {
            // Already in step 2 if resending
            startResendCodeTimer()
        }

    } catch(error) {
      // Something else went wrong with the backend
      handleError(resend ? 2 : 1, error, 'seconds')
    } finally {
      hideLoadingIndicator()
    }
}


async function step2_checkVerificationCode(code = null) {
    const errorEl = document.getElementById('step2-virhe')

    errorEl.innerHTML = ''

    try {
        passwordResetCode = code || [ ...document.getElementsByTagName('input') ]
            .filter(({ name }) => name && name.includes('verification-code'))
            .map(({ value }) => value)
            .join('')

        if(passwordResetCode.length !== 6) {
            if(code) setStep(1)
            return
        }

        showLoadingIndicator()

        console.log('debug')

        const url = `/api/user/check-verification-code`

        const payload = { email: passwordResetEmail, code: passwordResetCode }

        await backendApiKutsu('POST', url, payload)

        setStep(3)
    } catch(error) {
        if(code) {
            // We came from first-user email, something went wrong = just go back
            // to start of "Forgot password"
            return setStep(1)
        }

        if(error?.status === 401) {
            errorEl.innerHTML = 'Tarkista vahvistuskoodi'
        } else {
            handleError(2, error)
        }
    } finally {
        hideLoadingIndicator()
    }
}

async function step3_setNewPassword(e) {
    e.preventDefault()

    try {
        const newPasswordEl = document.getElementById('uusi-salasana-step3')
        const newPasswordAgainEl = document.getElementById('uusi-salasana-step3-vahvistus')
    
        const errorEl = document.getElementById('step3-virhe')
    
        errorEl.innerHTML = ''
    
        const newPassword = newPasswordEl?.value
        const newPasswordAgain = newPasswordAgainEl?.value
    
        if(newPassword !== newPasswordAgain) {
            errorEl.innerHTML = 'Salasanat eivät täsmää. Tarkista että kirjoitit molemmat salasanat oikein.'
            return
        }
    
        if(!isStrongPassword(newPassword)) {
            errorEl.innerHTML = 'Salasanan tulee olla vähintään 6 merkkiä pitkä.'
            return
        }

        const staySignedInCheckboxEl = document.getElementById('uusi-salasana-step3-pysy-kirjautuneena')
        const staySignedIn = staySignedInCheckboxEl?.checked || false

        showLoadingIndicator()

        const url = `/api/user/reset-password`

        const payload = {
            email: passwordResetEmail,
            code: passwordResetCode,
            password: newPassword,
            staySignedIn
        }

        await backendApiKutsu('POST', url, payload)

        window.location.replace('/')

    } catch(error) {
        console.error(error)
        errorEl.innerHTML = 'Palvelinvirhe. Jos ongelma jatkuu, ota yhteys kurssit@nurminen.dev'
        notyf.error('Palvelinvirhe')
    } finally {
        hideLoadingIndicator()
    }
}

function setStep(step) {
    const step1El = document.getElementById('salasana-step1')
    const step2El = document.getElementById('salasana-step2')
    const step3El = document.getElementById('salasana-step3')

    if(step1El) step1El.style.display = step === 1 ? 'block' : 'none'
    if(step2El) step2El.style.display = step === 2 ? 'block' : 'none'
    if(step3El) step3El.style.display = step === 3 ? 'block' : 'none'

    if(step === 1) {
        if(passwordResetEmail) {
            const emailInputEl = document.getElementById('uusi-salasana-step1-email')

            emailInputEl.value = passwordResetEmail
        }
    } else if(step === 2) {
        const firstCodeInputEl = document.getElementById('first-code-input')

        if(firstCodeInputEl) {
            firstCodeInputEl.focus()
        }

        startResendCodeTimer()
    } else if(step === 3) {
        const userEmailEl = document.getElementById('kayttajan-sahkoposti')

        if(userEmailEl) {
            userEmailEl.innerHTML = passwordResetEmail
        }
    }
}


let resendDisabled = false

function startResendCodeTimer(seconds = 29) {
    const resendCodeEl = document.getElementById('laheta-uusi-koodi')

    let secondsLeft = seconds

    resendCodeEl.innerHTML = `Lähetä uusi koodi (${secondsLeft + 1})`
    resendCodeEl.disabled = true
    resendDisabled = true

    const resendTimer = setInterval(() => {
        resendCodeEl.innerHTML = `Lähetä uusi koodi (${secondsLeft})`
        secondsLeft -= 1

        if(secondsLeft === -1) {
            resendCodeEl.disabled = false
            resendDisabled = false
            resendCodeEl.innerHTML = 'Lähetä uusi koodi'
            clearInterval(resendTimer)
        }
    }, 1000)

}


function setupPasswordResetCodeInputs() {
    // https://codepen.io/RobertAron/pen/gOLLXLo
    const inputElements = [ ...document.querySelectorAll('input.code-input') ]

    inputElements.forEach((ele,index) => {
        ele.addEventListener('keydown', (e) => {
            if(e.keyCode === 8 && e.target.value==='') inputElements[Math.max(0,index-1)].focus()
        })

        ele.addEventListener('input', (e) => {
            const [ first, ...rest ] = e.target.value
            e.target.value = first ?? ''
            const lastInputBox = index === inputElements.length - 1
            const insertedContent = first !== undefined
            if(insertedContent && !lastInputBox) {
                inputElements[index+1].focus()
                inputElements[index+1].value = rest.join('')
                inputElements[index+1].dispatchEvent(new Event('input'))
            }
        })
    })
}

function clearPasswordResetCodeInput() {
    const inputElements = [...document.querySelectorAll('input.code-input')]

    inputElements.forEach((ele, index) => {
      ele.value = ''
    })
}



//
//
//  ██████╗ ██████╗ ██╗   ██╗██████╗ ███████╗███████╗
// ██╔════╝██╔═══██╗██║   ██║██╔══██╗██╔════╝██╔════╝
// ██║     ██║   ██║██║   ██║██████╔╝███████╗█████╗
// ██║     ██║   ██║██║   ██║██╔══██╗╚════██║██╔══╝
// ╚██████╗╚██████╔╝╚██████╔╝██║  ██║███████║███████╗
//  ╚═════╝ ╚═════╝  ╚═════╝ ╚═╝  ╚═╝╚══════╝╚══════╝
//
//
async function setCoursePage(userData) {
    const courseEl = document.getElementById('kurssi-sisalto')
    const course = courseEl?.dataset?.kurssi
    const free = courseEl?.dataset?.maksuton

    if(!course) return

    const url = `/api/kurssi/${course}`

    try {
        const response = await backendApiKutsu('GET', url)

        courseEl.innerHTML = response.courseHtml
    
        await nextTick()
        await nextTick()
    
        await setupCourse(course)
    } catch(error) {
        if(free !== '1' && error?.status === 403) {
            // Logged in but no access to course
            const loginOpenCourseEl = document.getElementById('kirjaudu-avaa-kurssi')
            loginOpenCourseEl.style.display = 'none'

            const buyCourseForUserTextEl = document.getElementById('osta-kurssi-kayttajalle-teksti')
            buyCourseForUserTextEl.innerHTML = `Kurssi liitetään nykyiseen käyttäjätiliisi kun syötät ostaessa sähköpostin: <strong>${userData.email}</strong>`

            const buyCourseLinkEl = document.getElementById('osta-kurssi-linkki')

            buyCourseLinkEl.href = `${buyCourseLinkEl.href}?email=${userData.email}`
        } else {
            // Backend error
            const buyOrOpenCourseEl = document.getElementById('avaa-kurssi-sisalto')
            buyOrOpenCourseEl.style.display = 'none'

            const openCourseErrorEl = document.getElementById('avaa-kurssi-virhe')
            openCourseErrorEl.style.display = 'block'

        }

    } finally {

    }
}


async function setupCourse(course) {
    // Dynamic import highlight.js stuff
    const { default: hljs }         = await import('highlight.js/lib/core')
    const { default: css }          = await import('highlight.js/lib/languages/css')
    const { default: dockerfile }   = await import('highlight.js/lib/languages/dockerfile')
    const { default: javascript }   = await import('highlight.js/lib/languages/javascript')
    const { default: nginx }        = await import('highlight.js/lib/languages/nginx')
    const { default: plaintext }    = await import('highlight.js/lib/languages/plaintext')
    const { default: xml }          = await import('highlight.js/lib/languages/xml')
    const { default: yaml }         = await import('highlight.js/lib/languages/yaml')

    await import('highlight.js/styles/monokai-sublime.css')

    hljs.registerLanguage('css',        css)
    hljs.registerLanguage('dockerfile', dockerfile)
    hljs.registerLanguage('html',       xml)
    hljs.registerLanguage('javascript', javascript)
    hljs.registerLanguage('nginx',      nginx)
    hljs.registerLanguage('plaintext',  plaintext)
    hljs.registerLanguage('yaml',       yaml)

    hljs.highlightAll()

    restoreScrollPosition(course)
}


function restoreScrollPosition(course) {
    const scrollPos = localStorage.getItem(`${course}_scrollPos`)

    if(scrollPos) window.scrollTo(0, scrollPos)
}



//
//
// ███╗   ███╗██╗███████╗ ██████╗
// ████╗ ████║██║██╔════╝██╔════╝
// ██╔████╔██║██║███████╗██║
// ██║╚██╔╝██║██║╚════██║██║
// ██║ ╚═╝ ██║██║███████║╚██████╗
// ╚═╝     ╚═╝╚═╝╚══════╝ ╚═════╝
//
//

export function showLoadingIndicator(text = '') {
    const loadingIndicatorEl = document.getElementById('loading-indicator')

    if(!loadingIndicatorEl) return

    loadingIndicatorEl.style.display = 'flex'

    const loadingTextEl = document.getElementById('loading-indicator-text')

    if(!loadingTextEl) return

    if(text) {
        loadingTextEl.style.display = 'block'
        loadingTextEl.innerHTML = text
    } else {
        loadingTextEl.style.display = 'none'
    }
}

export function hideLoadingIndicator() {
    const loadingIndicatorEl = document.getElementById('loading-indicator')

    if(!loadingIndicatorEl) return

    loadingIndicatorEl.style.display = 'none'

    const loadingTextEl = document.getElementById('loading-indicator-text')

    if(!loadingTextEl) return

    loadingTextEl.display = 'none'
    loadingTextEl.innerHTML = ''
}

function loginLoading(loading = true) {
    const loginButtonEl = document.getElementById('tieto-kirjaudu-nappi')

    if(loading) {
        loginButtonEl.classList.add('is-loading')
    } else {
        loginButtonEl.classList.remove('is-loading')
    }

    const courseLoginButtonEl = document.getElementById('kurssi-kirjaudu-nappi')

    if(!courseLoginButtonEl) return

    if(loading) {
        courseLoginButtonEl.classList.add('is-loading')
    } else {
        courseLoginButtonEl.classList.remove('is-loading')
    }
}



function toggleLightDarkMode() {
    const htmlEl = document.documentElement

    let currentTheme = htmlEl.dataset.theme

    let newTheme

    if(currentTheme === 'light') newTheme = 'dark'
    if(currentTheme === 'dark')  newTheme = 'light'

    document.documentElement.setAttribute('data-theme', newTheme)

    localStorage.setItem('tieto_kurssit_theme', newTheme)
}


function nextTick() {
    return new Promise(resolve => requestAnimationFrame(resolve))
}


function isStrongPassword(password) {
    if(typeof password !== 'string') return false
  
    return password.length >= 6
  }
  



//
//
// ██████╗  ██████╗ ███╗   ███╗
// ██╔══██╗██╔═══██╗████╗ ████║
// ██║  ██║██║   ██║██╔████╔██║
// ██║  ██║██║   ██║██║╚██╔╝██║
// ██████╔╝╚██████╔╝██║ ╚═╝ ██║
// ╚═════╝  ╚═════╝ ╚═╝     ╚═╝
//
//
document.addEventListener('DOMContentLoaded', async () => {
    MicroModal.init()

    //
    // Login dropdown bindings
    //
    const loginMenuEl = document.getElementById('login-menu')
    const loginMenuTriggerEl = document.getElementById('login-menu-trigger')
    if(loginMenuEl && loginMenuTriggerEl) {
        loginMenuTriggerEl.addEventListener('click', () => {
            loginMenuEl.classList.toggle('is-active')
        })
    }

    document.addEventListener('click', e => {
        if(!loginMenuEl.contains(e.target)) {
            loginMenuEl.classList.remove('is-active')
        }
    })

    const loginFormEl = document.getElementById('tieto-login-form')
    loginFormEl.addEventListener('submit', login.bind(null, 'top-login'))


    //
    // Course page register & login
    //
    const registerFormEl = document.getElementById('kurssi-rekisteroidy')
    if(registerFormEl) {
        registerFormEl.addEventListener('submit', register.bind(null))
    }

    const courseLoginFormEl = document.getElementById('kurssi-login-form')
    if(courseLoginFormEl) {
        courseLoginFormEl.addEventListener('submit', login.bind(null, 'course-login'))
    }


    //
    // User dropdown bindings
    //
    const userMenuEl = document.getElementById('user-menu')
    const userMenuTriggerEl = document.getElementById('user-menu-trigger')
    if(userMenuEl && userMenuTriggerEl) {
        userMenuTriggerEl.addEventListener('click', () => {
            userMenuEl.classList.toggle('is-active')
        })

        const logoutLinkEl = document.getElementById('kirjaudu-ulos-linkki')

        if(logoutLinkEl) {
            logoutLinkEl.addEventListener('click', () => {
                logout()
            })
    
        }
    }

    document.addEventListener('click', e => {
        if(!userMenuEl.contains(e.target)) {
            userMenuEl.classList.remove('is-active')
        }
    })


    //
    // Top bar light/dark mode toggler
    //
    const lightDarkTogglerEl = document.getElementById('light-dark-toggler')
    if(lightDarkTogglerEl) {
        lightDarkTogglerEl.addEventListener('pointerup', toggleLightDarkMode)
    }


    // Frontpage "Notify me when course published" inputs
    const frontPageEl = document.getElementById('tieto-etusivu')
    if(frontPageEl) {
        mailingListHandler('etusivu')
    }

    await authenticate()

    // If we're on "Forgot password" -page
    setPasswordPage()
})



//
//
//  █████╗ ██████╗ ██╗
// ██╔══██╗██╔══██╗██║
// ███████║██████╔╝██║
// ██╔══██║██╔═══╝ ██║
// ██║  ██║██║     ██║
// ╚═╝  ╚═╝╚═╝     ╚═╝
//
//
export async function backendApiKutsu(method, apiUrl, data = null) {
    const options = {
        method, headers: {},
    }

    if(data) {
        // Asetetaan Content-Type koska lähetämme JSON muotoista dataa
        options['headers']['Content-Type'] = 'application/json'
        options['body'] = JSON.stringify(data)
    }

    const response = await fetch(apiUrl, options)

    if(!response.ok) {
        const error = new Error(`${apiUrl}: ${response.status} ${response.statusText}`)
        error.status = response.status
        error.body = await response.text()

        const retryAfter = response.headers.get('Retry-After')

        if(retryAfter) error.retryAfter = retryAfter

        throw error
    }

    const body = await response.text()

    try {
        return JSON.parse(body)
    } catch(error) {
        return body
    }
}

function handleError(step, error, retryUnit = 'minutes') {
    const errorEl = document.getElementById(`step${step}-virhe`)

    errorEl.innerHTML = ''

    if(error?.status === 429) {
        if(error?.retryAfter) {
            const retryAfter = retryUnit === 'minutes' ? Math.round(error.retryAfter / 60) : error.retryAfter
            const unitTxt = retryUnit === 'minutes' ? 'minuutin' : 'sekunnin'
            errorEl.innerHTML = `Liian monta pyyntöä, yritä uudelleen ${retryAfter} ${unitTxt} kuluttua.`
        } else {
            errorEl.innerHTML = 'Liian monta pyyntöä, yritä uudelleen 15 minuutin kuluttua.'
        }
    } else {
        console.error(error)
        errorEl.innerHTML = 'Palvelinvirhe. Jos ongelma jatkuu, ota yhteys kurssit@nurminen.dev'
        notyf.error('Palvelinvirhe')
    }
}